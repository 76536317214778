import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Box, Drawer, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NavLink from './NavLink';
import backgroundImage from '../Imgs/home.jpg';
import logo from '../Imgs/Magnet_logo-removebg.png';
import '../Css/Header_css.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { getDocs, query, collection, where } from 'firebase/firestore';
function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        setScrolled(window.scrollY > 50);
      };

      window.addEventListener('scroll', handleScroll);
      console.log('Scroll listener added');

      return () => {
        window.removeEventListener('scroll', handleScroll);
        console.log('Scroll listener removed');
      };
    }
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userQuery = query(collection(db, 'users'), where('email', '==', user.email));
        const querySnapshot = await getDocs(userQuery);
        const userDocId = querySnapshot.docs[0].id;
        const userDoc = doc(db, 'users', userDocId);
        const userSnap = await getDoc(userDoc);

        if (userSnap.exists()) {
          const data = userSnap.data();
          setIsAdmin(data.role === 'admin');
        }
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const drawer = (
    <div className="drawer-list">
      <NavLink to="home-container" scrolled={scrolled} isDrawer={true} onClick={handleDrawerToggle}>
        Home
      </NavLink>
      <NavLink to="/signin" isExternal={true} scrolled={scrolled} isDrawer={true} onClick={handleDrawerToggle}>
        Sign In
      </NavLink>
      {isAdmin && (
      <NavLink to="/checkOrder" isExternal={true} scrolled={scrolled} isDrawer={true} onClick={handleDrawerToggle}>
      Check Orders
      </NavLink>
      )}
      <NavLink to="/order" isExternal={true} scrolled={scrolled} isDrawer={true} onClick={handleDrawerToggle}>
        Order
      </NavLink>
      {/* <NavLink to="gallery-section" scrolled={scrolled} isDrawer={true} onClick={handleDrawerToggle}>
        Gallery
      </NavLink> */}
      <NavLink to="reviews-section" scrolled={scrolled} isDrawer={true} onClick={handleDrawerToggle}>
        Reviews
      </NavLink>
      <NavLink to="about-section" scrolled={scrolled} isDrawer={true} onClick={handleDrawerToggle}>
        About Us
      </NavLink>
    </div>
  );

  return (
    <Box>
      <Box
        sx={{
          minHeight: '100vh',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          color: '#FFFFFF',
        }}
        className="header-box"
      >
        <AppBar position="fixed" className={`app-bar ${scrolled ? 'scrolled' : ''}`}>
          <Toolbar className="toolbar">
            <IconButton
              edge="start"
              color="inherit"
              component={NavLink}
              to="/"
              sx={{ padding: 0, '&:hover': { backgroundColor: 'transparent' } }}
            >
              <img src={logo} alt="Logo" className="logo" />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <NavLink to="home-section" scrolled={scrolled}>
                Home
              </NavLink>
              <NavLink to="/signin" isExternal={true} scrolled={scrolled}>
                Sign In
              </NavLink>
              {isAdmin && (
              <NavLink to="/checkOrder" isExternal={true} scrolled={scrolled}>
                Check Orders
              </NavLink>
              )}
              <NavLink to="/order" isExternal={true} scrolled={scrolled}>
                Order
              </NavLink>
              {/* <NavLink to="gallery-section" scrolled={scrolled}>
                Gallery
              </NavLink> */}
              <NavLink to="reviews-section" scrolled={scrolled}>
                Reviews
              </NavLink>
              <NavLink to="about-section" scrolled={scrolled}>
                About Us
              </NavLink>
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
              <IconButton edge="end" color="inherit" onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{ paper: 'drawer-paper' }}
          ModalProps={{ keepMounted: true }}
        >
          {drawer}
        </Drawer>
        <Box className="hero-box">
          <Typography
            variant="h2"
            component="h1"
            sx={{
              color: 'white',
              fontFamily: '"Tangerine", cursive',
              fontSize: { xs: '3rem', sm: '5rem' },

            }}
          >
            Keep Your Memories Magnified
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
