import React, { useState } from 'react';
import { FaInfoCircle, FaCheckCircle, FaUpload } from 'react-icons/fa';
import '../Css/OrderNotes_css.css';

const OrderNotes = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="order-notes-container">
      <div className={`order-notes ${isExpanded ? 'expanded' : ''}`}>
        <div className="notes-header" onClick={toggleAccordion}>
          <FaInfoCircle className="notes-icon" />
          <h2>Important Order Notes</h2>
          <span className={`accordion-arrow ${isExpanded ? 'expanded' : ''}`}>
            {isExpanded ? '▲' : '▼'}
          </span>
        </div>
        <div className={`notes-content ${isExpanded ? 'expanded' : ''}`}>
          <ul>
            <li>
              <FaCheckCircle className="note-icon" />
              High-quality square images are required.
            </li>
            <li>
              <FaCheckCircle className="note-icon" />
              Delivery charges: $4 all over Lebanon.
            </li>
            <li>
              <FaUpload className="note-icon" />
              Submit your order once all pictures are uploaded successfully.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OrderNotes;
