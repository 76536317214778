import React from 'react';
import '../Css/PromoSection_css.css'; // Create a CSS file for styles if using CSS Modules or traditional CSS
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
const PromoSection = () => {
  const ProductIntroText = styled(Typography)({
    color: '#f5f5f5',
    background: '#0001',
    fontWeight: 'bold',
    marginBottom: '20px',
  });
  
    return (
      <div className="promo-section">
        <div className="promo-content">
        {/* <ProductIntroText variant="h4" className="promo-text">Order Your Magnets Now And Keep Your Memories Forever</ProductIntroText> */}
          <Link to="/order" className="order-button">
            <i className="fas fa-shopping-cart"></i> Order Now
          </Link>
          <a href="https://wa.me/+96181207946" className="whatsapp-button">
            <i className="fab fa-whatsapp"></i>Contact Us on WhatsApp
          </a>
        </div>
      </div>
    );
  };
  
  export default PromoSection;
