import React, { useRef, useEffect, useState } from 'react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { db } from './firebaseConfig';
import '../Css/OrderForm_css.css';
import Footer from './Footer';
import Header from './Header';
import ReviewsSection from './ReviewsSection';
import OrderNotes from './OrderNotes';
import { v4 as uuidv4 } from 'uuid';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Box, Typography, Card, CardContent, CardMedia, Button, TextField, Grid, CircularProgress, Snackbar, Alert, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/system';
import package1 from "../Imgs/magnet3.jpeg";

// Styled components
const SectionContainer = styled(Box)({
  padding: '40px',
  backgroundColor: '#f5f5f5',
  textAlign: 'center',
});

const PackageCard = styled(Card)({
  margin: '20px auto',
  maxWidth: '350px',
  borderRadius: '12px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
  padding: '10px',
});

const OrderButton = styled(Button)({
  marginTop: '15px',
  backgroundColor: '#593EA1',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#452F82',
  },
});

const OrderForm = () => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [orderDetails, setOrderDetails] = useState({ name: '', email: '', phone: '', address: '' });
  const [imageFiles, setImageFiles] = useState([]); // Store selected files
  const [imageUrls, setImageUrls] = useState([]); // Store uploaded image URLs
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const orderFormRef = useRef(null);
  const refId = useRef(uuidv4());

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setOrderDetails(prevDetails => ({
          ...prevDetails,
          email: user.email
        }));
      }
    });
    return () => unsubscribe();
  }, []);

  const packages = [
    { id: 1, name: '3 Magnets', price: 10, magnets: 3, description: ', 5x5 cm' },
    { id: 2, name: '6 Magnets (+1 Free)', price: 16, magnets: 6, description: ', 5x5 cm' },
    { id: 3, name: '9 Magnets (+2 Free)', price: 20, magnets: 9, description: ', 5x5 cm' },
    { id: 4, name: '12 Magnets (+2 Free)', price: 25, magnets: 12, description: ', 5x5 cm' },
    { id: 5, name: '18 Magnets', price: 30, magnets: 18, description: ', 5x5 cm' },
  ];

  const handlePackageSelect = (event) => {
    const selectedPackageId = event.target.value;
    const pkg = packages.find((pkg) => pkg.id === selectedPackageId);
    setSelectedPackage(pkg);
    setTimeout(() => {
      if (orderFormRef.current) {
        orderFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 0);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrderDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;

    if (files.length !== selectedPackage.magnets) {
      setError(`Please upload exactly ${selectedPackage.magnets} images.`);
      return;
    }

    setImageFiles(Array.from(files)); // Store selected files
    setError('');
  };

  const deleteExistingImages = async () => {
    const storage = getStorage();
    const folderRef = ref(storage, `images/${refId.current}`);
    try {
      const listResult = await listAll(folderRef);
      const deletePromises = listResult.items.map((itemRef) => deleteObject(itemRef));
      await Promise.all(deletePromises);
    } catch (error) {
      console.error("Error deleting existing images:", error);
    }
  };

  const uploadImagesToStorage = async () => {
    setUploading(true);
    await deleteExistingImages();
    const storage = getStorage();
    const uploadPromises = imageFiles.map((file) => {
      const storageRef = ref(storage, `images/${refId.current}/${file.name}`);
      return uploadBytes(storageRef, file).then(async (snapshot) => {
        const downloadUrl = await getDownloadURL(snapshot.ref);
        return downloadUrl;
      });
    });

    try {
      const urls = await Promise.all(uploadPromises);
      if (urls.length !== selectedPackage.magnets) {
        setError(`Failed to upload ${selectedPackage.magnets} images. Please try again.`);
        return null;
      }
      setImageUrls(urls);
      setError('');
      return urls;
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("Failed to upload images. Please try again.");
      return null;
    } finally {
      setUploading(false);
    }
  };

  const calculateTotalPrice = () => {
    if (selectedPackage) {
      return selectedPackage.price;
    }
    return 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (imageFiles.length !== selectedPackage.magnets) {
      setError(`Please upload exactly ${selectedPackage.magnets} images.`);
      return;
    }

    // Upload images to Firebase Storage
    const uploadedImageUrls = await uploadImagesToStorage();
    if (!uploadedImageUrls) {
      return; // Stop if image upload fails
    }

    const orderData = {
      Address: orderDetails.address,
      Date: Timestamp.now(),
      Email: orderDetails.email,
      Name: orderDetails.name,
      Package: selectedPackage.name,
      Phone: orderDetails.phone,
      Price: calculateTotalPrice(),
      client_feedback: null,
      imageUrls: uploadedImageUrls,
      order_delivered: false,
      order_done: false,
      refId: refId.current,
    };

    try {
      const docRef = await addDoc(collection(db, 'orders'), orderData);
      console.log('Document written with ID: ', docRef.id);
      setSelectedPackage(null);
      setOrderDetails({ name: '', email: '', phone: '', address: '' });
      setImageFiles([]);
      setImageUrls([]);
      setSnackbarMessage("Order submitted successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error adding document: ', error);
      setSnackbarMessage("Failed to submit order. Please try again.");
      setSnackbarOpen(true);
    }
  };

  return (
    <div className="product-page">
      <OrderNotes />

      {/* Package Selection Section */}
      <SectionContainer>
      <Typography variant="h4" style={{ marginBottom: "30px", fontWeight: "bold", color: "#333" }}>
        Select Your Magnet Package
      </Typography>
      <FormControl fullWidth style={{ marginBottom: '20px' }}>
        <InputLabel id="package-select-label">Choose a Package</InputLabel>
        <Select
          labelId="package-select-label"
          id="package-select"
          value={selectedPackage ? selectedPackage.id : ''}
          onChange={handlePackageSelect}
          label="Choose a Package"
        >
          {packages.map((pkg) => (
            <MenuItem 
              key={pkg.id} 
              value={pkg.id}
              style={{ 
                whiteSpace: 'normal', // Allow text to wrap
                height: 'auto',      // Adjust height automatically
                paddingTop: '8px',   // Adjust padding as needed
                paddingBottom: '8px' // Adjust padding as needed
              }}
            >
              {pkg.name} - ${pkg.price} ({pkg.description})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </SectionContainer>

      {/* Order Form Section */}
      <Box ref={orderFormRef} sx={{ maxWidth: '800px', margin: '40px auto', padding: '20px', backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', scrollMarginTop: '100px' }}>
        {selectedPackage && (
          <>
            <Typography variant="h5" style={{ marginBottom: '20px', fontWeight: 'bold', color: '#593EA1' }}>
              Order Details
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={orderDetails.name}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    value={orderDetails.email}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    type="tel"
                    value={orderDetails.phone}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    value={orderDetails.address}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <input
                    type="file"
                    multiple
                    onChange={handleImageUpload}
                    required
                    style={{ display: 'none' }}
                    id="upload-images"
                  />
                  <label htmlFor="upload-images">
                    <Button variant="contained" component="span" fullWidth>
                      Upload Images
                    </Button>
                  </label>
                  {uploading && <CircularProgress style={{ marginTop: '10px' }} />}
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
                    {imageFiles.map((file, index) => (
                      <img
                        key={index}
                        src={URL.createObjectURL(file)} // Display local file preview
                        alt={`Selected ${index + 1}`}
                        style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                      />
                    ))}
                  </Box>
                  {error && <Typography color="error" style={{ marginTop: '10px' }}>{error}</Typography>}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ fontWeight: 'bold', color: '#333' }}>
                    Total Price: ${calculateTotalPrice()}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={uploading || !orderDetails.name || !orderDetails.email || !orderDetails.phone || !orderDetails.address}
                  >
                    {uploading ? 'Uploading...' : 'Submit Order'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </Box>

      <ReviewsSection />
      <Footer />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={error ? "error" : "success"}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default OrderForm;